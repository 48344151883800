/**
 * Aside menu config
 *
 * To more info about config of menu items go to knowledgebase
 */
//import store from "@/core/services/store";
import checkMyReportForbidden from "./helpers/checkMyReportForbidden";

export default {
    // Root level also must be configured as menu item (must have submenu key, other ignored)
    submenu: {
        // Root (not only root) menu items text delimiter
        my_forms: {
            active: true,
            to: {
                name: "user.forms.list",
                params: {}
            },
            text: {
                code: "review.menu.my_forms",
                default: "Questionnaires for rating"
            },
            b_icon: "card-checklist",
        },
        my_reports: {
            active: function(storage_getters) {
                const user = storage_getters.currentUser;
                return user ? !checkMyReportForbidden(user, 'worker') : false;
            },
            to: {
                name: "user.reports",
                params: function(storage_getters) {
                    return { id: storage_getters.currentUserId };
                },
            },
            text: {
                code: "review.menu.my_reports",
                default: "My reports"
            },
            b_icon: "graph-up",
        },
        head: {
            active: function(storage_getters) {
                const user = storage_getters.currentUser;
                return user ? user.is_head : false;
            },
            text: {
                code: "review.menu.head",
                default: "Head of departments"
            },
            b_icon: "people-fill",
            submenu: {
                head_reports: {
                    active: function(storage_getters) {
                        const user = storage_getters.currentUser;
                        return user ? !checkMyReportForbidden(user, 'head') && user.is_head : false;
                    },
                    to: {
                        name: "head.reports",
                        params: {},
                    },
                    text: {
                        code: "review.menu.head_reports",
                        default: "Reports by departments"
                    },
                    b_icon: "graph-up",
                },
                head_publish: {
                    active: true,
                    to: {
                        name: "head.forms.publish",
                        params: {},
                    },
                    text: {
                        code: "review.menu.publish",
                        default: "Publish reports"
                    },
                    b_icon: "journal-check",
                },
            }

        },
        administration: {
            active: function(storage_getters) {
                const user = storage_getters.currentUser;
                return user ? user.is_admin : false;
            },
            text: {
                code: "review.menu.administration",
                default: "Administration"
            },
            b_icon: "shield-fill-check",
            submenu: {
                generation: {
                    active: true,
                    to: {
                        name: "admin.choose.launch.type",
                        params: {},
                    },
                    text: {
                        code: "review.menu.generation",
                        default: "Start review"
                    },
                    b_icon: "arrow-repeat",
                },
                admin_forms: {
                    active: true,
                    to: {
                        name: "admin.forms.list",
                        params: {},
                    },
                    text: {
                        code: "review.menu.admin_forms",
                        default: "Questionnaires"
                    },
                    b_icon: "card-checklist",
                },
                admin_publish: {
                    active: true,
                    to: {
                        name: "admin.forms.publish",
                        params: {},
                    },
                    text: {
                        code: "review.menu.publish",
                        default: "Publish reports"
                    },
                    b_icon: "journal-check",
                },
                admin_reports: {
                    active: true,
                    to: {
                        name: "admin.reports",
                        params: {},
                    },
                    text: {
                        code: "review.menu.admin_reports",
                        default: "Reports"
                    },
                    b_icon: "graph-up"
                },
                department_reports: {
                    active: true,
                    to: {
                        name: "department.reports",
                        params: {},
                    },
                    text: {
                        code: "review.menu.department_reports",
                        default: "Reports by departments"
                    },
                    b_icon: "person-lines-fill",
                },
                parameters: {
                    active: true,
                    to: {
                        name: "parameters",
                        params: {}
                    },
                    text: {
                        code: "review.menu.parameters",
                        default: "Parameters"
                    },
                    b_icon: "wrench"
                },
            }
        },
        help: {
            active: true,
            to: {
                name: "help",
                params: {},
            },
            text: {
              code: "review.menu.help",
              default: 'Help',
            },
            b_icon: "question-circle",
          },
    }
};
